@import '../../theme/colors';
.button {
  &__base {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px;
    border-radius: 3px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
    width: 250px;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
      transform: translateY(1px);
    }
  }
  &__primary {
    background-color: $primaryRegular;
    color: $greyRegular;
  }
  &__grey {
    background-color: $greyRegularDarker;
    color: $white;
  }
  &__greyLight {
    background-color: $greyLight;
    color: $white;
  }
  &__loaderimg {
    width: 20px !important;
    height: 20px !important;
    margin: 0 !important;
  }
}
