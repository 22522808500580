@import '../../../theme/colors';

.signin {
  &__link {
    display: block;
    margin-top: 10px;
    color: $white;
    text-decoration: none;
    font-size: 14px;
    a {
      text-decoration: none;
      color: $primaryRegular;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  &__msgReset {
    display: block;
    margin-bottom: 15px;
    color: $white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    max-width: 300px;
    span {
      font-weight: 700;
      color: $primaryRegular;
    }
  }
}
