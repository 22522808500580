@import '../../theme/colors';

.offlineLayout {
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  &__logo {
    font-weight: 800;
    font-size: 28px;
    letter-spacing: 1px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 10px;
    span {
      margin-left: 4px;
      font-weight: 100;
    }
  }
  img {
    width: 200px;
    margin-bottom: 20px;
  }
  &__container {
    background-color: $greyRegularDarker;
    display: flex;
    align-self: center;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
  }
}
