.AppB {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #281e30;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
  padding: 20px 0px 20px 0px;
}

.App-link {
  color: #61dafb;
}
.userNoAddContainer {
  border-left: 15px solid white;
  max-width: 100%;
}
.userContainer2 {
  border-bottom: 1px solid white;
  text-align: left;
  padding: 0 10px 0 10px;
  margin-left: 0;
  margin-right: 10px;
  max-width: 100%;
}

.tableContainer {
  overflow-x: scroll !important;
  max-width: 100%;
}

th,
td {
  max-width: 150px !important;
  min-width: 150px !important;
  border: none !important;
  border-top: 1px solid white !important;
  border-right: 1px solid white !important;
}
th {
  font-weight: 400;
}
td {
  font-weight: 100;
  color: #ffffffd5;
}
th {
  padding: 4px 4px;
}
table {
  border-spacing: 0;
}

.globalContainer {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  max-width: 100%;
}

h3 {
  text-align: left;
  margin-left: 10px;
}

.categContainer {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  border-left: 10px solid black;
  max-width: 100%;
}

.filters {
  font-size: 18;
  font-weight: bold;
}
.filters a {
  color: #61dafb;
  cursor: pointer;
}

.fnln {
  font-weight: 100;
  font-size: 22px;
  color: #49ffc1;
}
.email {
  color: #fff;
  font-weight: 100;
  font-size: 15;
}
.companyname {
  font-weight: 100;
  font-size: 22px;
  color: #ffffff80;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
