@import '../../theme/colors';

.onlineRouter {
  height: calc(100%-120px);
  position: 'relative';
  &__container {
    display: flex;
    flex-direction: row;
  }
  &__leftContainer {
    padding-top: 85px;
    flex: 1;
    min-width: 300px;
    width: 300px;
    background-color: $greyRegularDarker;
    position: fixed;
    height: 100%;
  }
  &__mainContainer {
    margin-left: 300px;
    max-width: calc(100% - 315px);
    width: calc(100% - 315px);
    height: 100vh;
    padding-left: 15px;
    display: flex;
    justify-content: center;
  }
  &__menuLink {
    color: white;
    background-color: $greyRegularDarker;
    display: flex;
    padding: 20px;
    text-decoration: none;
    border-bottom: 1px solid #ffffff50;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
      background-color: $greyRegular;
      padding-left: 30px;
    }
    &:first-child {
      border-top: 1px solid #ffffff50;
      margin-top: 20px;
    }
  }
  &__loader {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 79;
    top: 0;
    left: 0;
    img {
      width: 60px;
      height: 60px;
    }
  }
  &__logout {
    color: $error;
  }
}
body {
  overflow-y: scroll;
}

.popup-content {
  border: none !important;
  background-color: transparent !important;
  padding: 0;
  width: auto !important;
  @media screen and (max-width: 800px) {
  }
}
