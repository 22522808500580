@import '../../../theme/colors';
.userPremium {
  height: 100vh;
  width: 100%;
  &__filtresContainer {
    padding: 20px;
    border: 1px solid #ffffff50;
    border-radius: 20px;
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
  }
  &__textCount {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 100;
    font-size: 20px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .button__base {
      width: auto;
      margin: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    &Link {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 10px;
      margin-left: 10px;
      &:hover {
        background-color: $greyRegularDarker;
      }
    }
    &LinkLock {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .filtresSelect {
    background-color: $greyLight;
    border-width: 0;
    div {
      background-color: $greyLight;
      border-width: 0;
      color: white;
    }
    &__option:hover {
      background-color: $greyRegularDarker;
    }
  }
  .filtresSelect__value-container {
    background-color: $greyLight;
  }
}
