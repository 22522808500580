@import '../../theme/colors';

.header {
  height: 90px;
  width: 300px;
  margin-bottom: 10px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 40;
  background-color: $greyRegularDarker;
  display: flex;
  justify-content: center;

  -webkit-box-shadow: -1px 11px 10px -6px rgba($greyRegularDarker, 0.85);
  -moz-box-shadow: -1px 11px 10px -6px rgba($greyRegularDarker, 0.85);
  box-shadow: -1px 11px 10px -6px rgba($greyRegularDarker, 0.85);
  &__container {
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    flex: 1 1 auto;
    flex-direction: column;
  }
  &__logo-box {
    align-self: center;
    vertical-align: center;
    text-decoration: none;
  }

  &__logo {
    font-weight: 800;
    font-size: 28px;
    letter-spacing: 1px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-decoration: none;
    margin-top: 6px;
    span {
      margin-left: 4px;
      font-weight: 100;
    }
  }

  &__options {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding-right: 20px;
  }
  &__options-secondary {
    font-size: 20px;
    font-weight: 300;
  }
  &__usermenu {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 2;
    background-color: $greyLight;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 20px;
    transition: transform 300ms;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      transition: transform 300ms;
    }
  }
}
